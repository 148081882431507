import axios from 'axios';
import qs from 'qs'
import {
    Message,
} from 'element-ui'
import router from '@/router';
import storage from '@/fetch/storage'
import i18n from "../i18n";

axios.defaults.baseURL = '/'

// axios
axios.defaults.timeout = 1000000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

//POST
axios.interceptors.request.use((config) => {
    let token = storage.get('token');
    let lang = localStorage.getItem('language');
    let infoMsg = storage.get('infoMsg');
    if (token) {
        config.headers["Authorization"] = token
    }
    if (lang) {
        config.headers["lang"] = lang === 'zh_CN' ? 'zh_CN' : 'en_US'
    }
    infoMsg ? config.headers["TENANT-ID"] = infoMsg.tenantId : ''
    return config
}, (error) => {
    return Promise.reject(error);
});

function toLogin() {
    console.log('token failed');
    storage.remove("token");
    Message({
        message: i18n.t('LoginFailed'),
        type: "warning"
    });
    router.replace({
        path: 'login'
    })
}

function buildURL(url, needToken) {
    if (!needToken) {
        return url = url + (url.indexOf('?') >= 0 ? "&" : "?");
    }
    let token = storage.get('token');
    if (!token) {
        storage.remove("token");
        router.replace('login')
    }
    return url
}

// POST
export function fetchPost(url, params, needToken, isJson, hideMsg, ContentType) {
    url = buildURL(url, needToken);
    if (!url) {
        return new Promise((resolve, reject) => {
            reject();
        })
    }
    return new Promise((resolve, reject) => {
        let header = ContentType || 'application/x-www-form-urlencoded'
        if (isJson) {
            header = 'application/json'
        }
        axios.post(url, (isJson || ContentType) ? params : qs.stringify(params), {
            headers: {
                'Content-Type': header,
            }
        }).then(response => {
            if (response.status === 200) {
                if (response.data.code === 200) {
                    resolve(response.data)
                } else if (response.data.code === 401) {
                    toLogin()
                } else {
                    Message({
                        message: response.data.msg,
                        type: "warning"
                    });
                    reject(response)
                }
            } else {
                reject(response)
                Message({
                    message: i18n.t('serverIsBusy'),
                    type: "warning"
                });

            }
        }).catch((error) => {
            reject(error)
            Message({
                message: i18n.t('networkError'),
                type: "warning"
            });
        })
    })
}

// GET
export function fetchGet(url, params, needToken, hideMsg) {
    url = buildURL(url, needToken);
    if (!url) {
        return new Promise((resolve, reject) => {
            reject();
        })
    }

    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params, paramsSerializer: function (params) {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        }).then(response => {
            if (response.status === 200) {
                if (response.data.code === 200) {
                    resolve(response.data)
                } else if (response.data.code === 401) {
                    toLogin()
                } else if (response.data.code === 110) {
                    resolve(response.data)
                } else {
                    if (!hideMsg) {
                        Message({
                            message: response.data.msg,
                            type: "warning"
                        });
                    } else {
                        resolve(response.data)
                    }
                }
            } else {
                reject(response)
                Message({
                    message: i18n.t('serverIsBusy'),
                    type: "warning"
                });
            }
        }).catch((error) => {
            console.log(error);
            reject(error)
            Message({
                message: i18n.t('networkError'),
                type: "warning"
            });
        })
    })
}

function downloadFile(url, filename, params = '', type = 'xlsx') {
    url = buildURL(url, true);
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params, paramsSerializer: function (params) {
                    return qs.stringify(params, {arrayFormat: 'repeat'})
                },
                responseType: "blob" // 指定响应类型为 blob
            })
            .then((response) => {
                const blob = new Blob([response.data]);
                const downloadUrl = URL.createObjectURL(blob);
                const downloadLink = document.createElement("a");
                downloadLink.href = downloadUrl;
                downloadLink.download = filename + '.' + type;
                downloadLink.click();
                URL.revokeObjectURL(downloadUrl);
                resolve(response.data)
            })
            .catch((error) => {
                console.error("Error downloading file:", error);
            });
    })

}

export default {
    page(requestType, url, params, needToken) {
        if (requestType == 'GET') {
            return fetchGet(url, params, needToken);
        }
        if (requestType == 'POST') {
            return fetchPost(url, params, needToken);
        }
    },
    // register
    register(params) { //邮箱
        return fetchPost('/member/web/register', params, false)
    },
    mobileRegister(params) {//手机
        return fetchPost('/member/web/register/mobile', params, false)
    },
    // send email
    sendEmail(params) {
        return fetchGet('/member/web/register/send/email', params, false)
    },
    sendMobile(params) {
        return fetchPost('/member/web/register/send/sms', params, false)
    },
    // login
    login(params) {
        return fetchPost('/member/web/login', params, false);
    },
    // update user info
    updateUserInfo(params) {
        return fetchPost('/member/web/user/update/info', params, true);
    },
    // Reset Password
    resetPassword(params) {
        return fetchPost('/member/web/user/update/password', params, false);
    },
    // change account
    changeAccount(params) {
        return fetchGet('/member/web/user/switch/account', params, true);
    },
    dashboardListUnit(params) {
        return fetchGet('/device/web/dashboard/unit/page', params, true, true);
    },
    dashboardListUnitPart(params) {
        return fetchGet('/device/web/dashboard/unit/part/page', params, true, true);
    },
    // dashboard list
    dashboardList(params) {
        return fetchGet('/device/web/dashboard/noPageList', params, true);
    },
    // alarm list
    alarmList(params) {
        return fetchGet('/alarm/web/alarm/noPageList', params, true);
    },
    // ack alarm
    ackAlarm(params) {
        return fetchPost('/alarm/web/alarm/accept', params, true);
    },
    // download device
    downloadDevice(params) {
        return fetchGet('/device/web/device/getData', params, true);
    },
    // device detail
    deviceDetail(params) {
        return fetchGet('/device/web/device/detail/info', params, true);
    },
    // device channel
    deviceChannel(params) {
        return fetchGet('/device/web/device/detail/channel', params, true);
    },
    // device log
    deviceLog(params) {
        return fetchGet('/device/web/device/detail/log', params, true);
    },
    // device chart
    getLogChart(params) {
        return fetchGet('/device/web/device/v2/detail/log/data', params, true)
    },
    // channel value
    channelValue(params) {
        return fetchGet('/device/web/channel/unit', params, true)
    },
    // device channel list
    deviceChannelList(params) {
        return fetchGet('/device/web/device/channel/list', params, true);
    },
    addDashboard(params) {
        return fetchPost('/device/web/dashboard/edit', params, true)
    },
    // delete dashboard
    deletDashboard(params) {
        return fetchGet('/device/web/dashboard/delete', params, true)
    },
    // dashboard info
    dashboardInfo(params) {
        return fetchGet('/device/web/dashboard/info', params, true);
    },
    // update dashboard status
    updateStatus(params) {
        return fetchGet('/device/web/dashboard/update/status', params, true)
    },

    // ALARM
    // user device
    getDevice(params) {
        return fetchGet('/device/web/device/getDevice', params, true)
    },
    // device channel
    getChannel(params) {
        return fetchGet('/device/web/device/getChannel', params, true)
    },
    // add alarm
    addAlarm(params) {
        return fetchPost('/alarm/web/alarm/addAlarm', params, true);
    },
    // update alarm
    updateAlarm(params) {
        return fetchPost('/alarm/web/alarm/updateAlarm', params, true);
    },
    // delete alarm
    deleteAlarm(params) {
        return fetchPost('/alarm/web/alarm/deleteAlarm', params, true);
    },
    // USERS
    // add user
    addUser(params) {
        return fetchPost('/member/web/user/add/user', params, true);
    },
    // update user
    updateUser(params) {
        return fetchPost('/member/web/user/edit/user', params, true);
    },
    // delete user
    deleteUser(params) {
        return fetchGet('/member/web/user/delete', params, true)
    },

    addDeviceConfirm(params) {
        return fetchPost('/device/web/device/update', params, true);
    },

    getAnalog(params) {
        return fetchGet('/device/web/channel/analog', params, true);
    },
    // disable device
    disableDevice(params) {
        return fetchPost('/device/web/device/updateStatus', params, true);
    },
    // device info
    deviceInfo(params) {
        return fetchGet('/device/web/channel/deviceInfo', params, true);
    },
    // channel header
    channelHeader(params) {
        return fetchGet('/device/web/channel/header', params, true);
    },
    channelHeaderV2(params) {
        return fetchGet('/device/web/channel/v2/header', params, true);
    },
    // channel list
    channelList(params) {
        return fetchGet('/device/web/channel/info', params, true);
    },
    channelListV2(params) {
        return fetchGet('/device/web/channel/v2/info', params, true);
    },
    // modify channel info
    modifyChannelInfo(params, id) {
        return fetchPost('/device/web/channel/modify?deviceId=' + id, params, true, true);
    },
    modifyChannelInfoV2(params, id) {
        return fetchPost('/device/web/channel/v2/modify?deviceId=' + id, params, true, true);
    },
    modifyModbusChannelInfo(params, id) {
        return fetchPost('/device/web/modbus/edit/channel?deviceId=' + id, params, true, true);
    },
    // do source
    doSource(params) {
        return fetchGet('/device/web/device/getDoSource', params, true)
    },

    rebootDevice(params) {
        return fetchPost('/device/web/device/restart', params, true);
    },
    // upload
    upload(params) {
        return fetchPost('/member/web/user/upload', params, true, false, true,'multipart/form-data');
    },
    save(params) {
        return fetchPost('/member/web/user/update/userInfo', params, true);
    },
    saveAlias(params) {
        return fetchPost('/member/web/user/change/alias', params, true);
    },
    createOrder(params) {
        return fetchPost('/device/web/order/create', params, true);
    },

    getInfoMsg(params) {
        return fetchGet('/member/web/user/get/UserInfo', params, true);
    },
    //SMS
    sms_balance(params) {
        return fetchGet('/device/web/order/findLeftSms', params, true);
    },
    sms_config(params) {
        return fetchGet('/push/web/sms/package/findSmsConfig', params, true);
    },
    smsUsedList(params) {
        return fetchGet('/device/web/order/smsUsedList', params, true);
    },
    smsOrderList(params) {
        return fetchGet('/device/web/order/list', params, true);
    },
    PlaceAnOrder(params) {
        return fetchPost('/device/web/order/createSms', params, true);
    },
    setUserPassword(params) {
        return fetchPost('/member/web/user/forgot/password', params);
    },
    getDashboardList(params) {
        return fetchGet('/device/web/dashboard/list', params, true);
    },
    getDashboardListAll(params) {
        return fetchGet('/device/web/dashboard/unit/all/page', params, true);
    },
    getAlarmLogList(params) {
        return fetchGet('/alarm/web/alarm/log/list', params, true);
    },
    getAlarmLogDetail(params) {
        return fetchGet('/alarm/web/alarm/log/detail', params, true);
    },
    getAlarmLogListPc(params) {
        return fetchGet('/alarm/web/alarm/log/lists', params, true);
    },
    getAlarmLogListPcV2(params) {
        return fetchGet('/alarm/web/alarm/v2/log/lists', params, true);
    },
    acceptAllAlarm(params) {
        return fetchPost('/alarm/web/alarm/accept/all', params, true, true);
    },
    acceptAllAlarmV2(params) {
        // alarm/web/alarm/v2/accept/all
        return fetchPost('/alarm/web/alarm/v2/accept/all', params, true, true);
    },
    getDeviceList(params) {
        return fetchGet('/device/web/device/list', params, true);
    },
    getUserAlertList(params) {
        return fetchGet('/alarm/web/alarm/list', params, true);
    },
    getDownloadList(params) {
        return fetchGet('/device/web/download/list', params, true);
    },
    getUserList(params) {
        return fetchGet('/member/web/user/list', params, true);
    },
    getUserFilterRoterList() {
        return fetchGet("/member/web/role/roles", {}, true);
    },
    getChannelList(params) {
        return fetchGet('/device/web/channel/v2/list', params, true);
    },
    getChannelListV1(params) {
        return fetchGet('/device/web/channel/list', params, true);
    },
    detailLogList(params) {
        return fetchGet('/device/web/device/detail/log/info', params, true);
    },
    detailLogListV2(params) {
        return fetchGet('/device/web/device/v2/detail/log/info', params, true);
    },
    bindAccount(params) {
        return fetchPost('/member/web/user/bind/mobile/or/email', params, true);
    },
    packageList(params) {
        return fetchGet('/device/web/package/list', params, true);
    },
    getMenus(params) {
        return fetchGet('/member/web/menu/v2/getRouters', params, true)
    },
    checkEmail(params) {
        return fetchPost('/member/web/user/checkEmail', params, true);
    },
    checkMobile(params) {
        return fetchPost('/member/web/user/checkMobile', params, true);
    },
    alarmDetailLogs(params) {
        return fetchGet('/alarm/web/alarm/log/detail/list', params, true);
    },
    getConfigKey(params) {
        return fetchGet('/system/web/config/key', params, true);
    },
    getNotificationList(params) {
        return fetchGet('/alarm/web/notification/log/list', params, true);
    },
    getDashboardAlarmList(params) {
        return fetchGet('/device/web/dashboard/alarm/unit/page', params, true);
    },
    getModbusAll(params) {
        return fetchGet('/device/web/modbus/all', params, true);
    },
    getModbusConfig(params) {
        return fetchGet('/device/web/modbus/search-config', params, true);
    },
    getParentDevices(params) {
        return fetchGet('/device/web/device/getDevices', params, true);
    },
    postParentDevices(params) {
        return fetchPost('/device/web/modbus/add', params, true);
    },
    postModbusConfig(params) {
        return fetchPost('/device/web/modbus/config', params, true, true);
    },
    postAddModbusChannel(params) {
        return fetchPost('/device/web/modbus/add/channel', params, true, true);
    },
    postDelModbusChannel(params) {
        return fetchPost('/device/web/modbus/del/channel', params, true);
    },
    getDeviceChannelInfo(params) {
        return fetchGet('/device/web/channel/deviceChannelInfo', params, true);
    },
    getDeviceChannelInfoV2(params) {
        return fetchGet('/device/web/channel/v2/deviceChannelInfo', params, true);
    },
    getDeviceChannelConfig(params) {
        return fetchGet('/device/web/channel/config', params, true);
    },
    getDevicePackList(params) {
        return fetchGet('/device/pack/log/web/list', params, true);
    },
    getLfhomeExhaustNum(params) {
        return fetchGet('/device/web/lfhome/exhaustNum', params, true);
    },
    getLfhomeList(params) {
        return fetchGet('/device/web/lfhome/list', params, true);
    },
    getLfhomeDeviceList(params) {
        return fetchGet('/device/web/lfhome/device/list', params, true);
    },
    postUserDisable(params) {
        return fetchPost("/member/web/account/disable", params, true);
    },
    roleList(params) {
        return fetchGet("/member/web/role/list", params, true);
    },
    addRole(params) {
        return fetchPost("/member/web/role/add", params, true,true);
    },
    uploadRole(params) {
        return fetchPost("/member/web/role/edit", params, true,true);
    },
    removeRole(params){
        return fetchPost(`/member/web/role/remove`,params, true);
    },
    roleMenuList(params){
        return fetchGet(`/member/web/menu/role/menu/list` ,params, true);
    },
    roleInfo(params){
        return fetchGet(`/member/web/role/info` ,params, true);
    },
    loginOut(params) {
        return fetchGet("/system/web/config/tenant/key", params, true);
    },
    pushConfig(params) {
        return fetchGet("/push/web/push/get/config", params, true);
    },
    editPushConfig(params) {
        return fetchPost("/push/web/push/edit/config", params, true);
    },
    pMachineFindStatistics(params) {
        return fetchGet("/device/web/machine/findStatistics", params, true);
    },
    pSensorAlarm(params) {
        return fetchGet("/alarm/web/alarm/get/sensor/alarm", params, true);
    },
    pGroupAlarm(params) {
        return fetchGet("/alarm/web/alarm/get/group/alarm", params, true);
    },
    pAlarmStatistics(params) {
        return fetchGet("/alarm/web/alarm/get/alarm/statistics", params, true);
    },
    pAlarmPendingCount(params) {
        return fetchGet("/alarm/web/alarm/pending/count", params, true);
    },
    pMachinePage(params) {
        return fetchGet("/device/web/machine/page", params, true);
    },
    pSensorTypeList(params) {
        return fetchGet("/device/web/machine/other/sensorType/list", params, true);
    },
    pMachineModelList(params) {
        return fetchGet("/device/app/machine/other/modbus/list", params, true);
    },
    pAddSensor(params) {
        return fetchPost("/device/web/modbus/v2/add", params, true, true);
    },
    editAddSensor(params) {
        return fetchPost("/device/web/modbus/v2/edit", params, true, true);
    },
    sensorInfo(params) {
        return fetchGet("/device/web/modbus/info", params, true);
    },
    sensorDetail(params) {
        return fetchGet("/device/web/modbus/detail", params, true);
    },
    addArea(params) {
        return fetchPost("/device/app/machine/area/add", params, true);
    },
    removeArea(params) {
        return fetchPost("/device/app/machine/area/delete", params, true);
    },
    editArea(params) {
        return fetchPost("/device/app/machine/area/edit", params, true);
    },
    areaList(params) {
        return fetchGet("/device/web/machine/area/list", params, true);
    },
    machineList(params) {
        return fetchGet("/device/web/machine/v3/list", params, true);
    },
    deleteMachine(params) {
        return fetchPost("/device/web/machine/delete", params, true);
    },
    machineTypeList(params) {
        return fetchGet("/device/web/machine/other/type/list", params);
    },
    addMachine(params) {
        return fetchPost("/device/web/machine/add", params, true, true);
    },
    editMachine(params) {
        return fetchPost("/device/web/machine/edit", params, true, true);
    },
    provinceList(params) {
        return fetchGet("/device/web/area/list", params, true, true);
    },
    machineLevelList(params) {
        return fetchGet("/device/web/machine/other/level/list", params, true);
    },
    isoStandardList(params) {
        return fetchGet("/device/web/machine/other/isoStandard/list", params, true);
    },
    machineInfo(params) {
        return fetchGet("/device/web/machine/info", params, true);
    },
    machineDetail(params) {
        return fetchGet("/device/web/machine/machineInfo", params, true);
    },
    machineUnbind(params) {
        return fetchPost("/device/web/machine/unbind", params, true, true);
    },
    machineAlarmCount(params) {
        return fetchGet("/alarm/web/alarm/get/machine/alarm/statistics", params, true);
    },
    sensorAlarm(params) {
        return fetchGet("/device/web/device/v4/detail/log/dataXY", params, true);
    },
    sensorAlarmV3(params) {
        return fetchGet("/device/web/device/v3/detail/log/data", params, true);
    },
    isoTypeList(params) {
        return fetchGet("/device/web/machine/other/isoType/list", params, true);
    },
    getAndSensor(params) {
        return fetchGet("/device/web/machine/getAndSensor", params, true);
    },
    getGroupTimeList(params) {
        return fetchGet("/device/web/device/get/group/time", params, true);
    },
    getGroupData(params) {
        return fetchGet("/device/web/device/get/group/data", params, true);
    },
    trainStart(params) {
        return fetchPost("/device/web/train/start", params, true);
    },
    getMachineState(params) {
        return fetchGet("/device/web/machine/get/machineState", params, true);
    },
    checkTrain(params) {
        return fetchPost("/device/web/train/check", params, true);
    },
    machineRecordList(params) {
        return fetchGet("/device/web/machineRecord/page", params, true);
    },
    addMachineRecord(params) {
        return fetchPost("/device/web/machineRecord/save", params, true, true);
    },
    machineRecordInfo(params) {
        return fetchGet("/device/web/machineRecord/info", params, true);
    },
    delMachineRecord(params) {
        return fetchPost("/device/web/machineRecord/deleted", params, true);
    },
    openMachine(params) {
        return fetchGet("/device/web/machine/open", params, true);
    },
    reasonList(params) {
        return fetchGet("/device/web/reason/list", params, true);
    },
    machineScore(params) {
        return fetchGet("/device/web/machine/get/machineScore", params, true);
    },
    inspectList(params) {
        return fetchGet("/device/web/inspect/list", params, true);
    },
    inspectDetail(params) {
        return fetchGet("/device/web/inspect/info", params, true);
    },
    addInspectInfo(params) {
        return fetchPost("/device/web/inspect/add", params, true, true)
    },
    editInspectInfo(params) {
        return fetchPost("/device/web/inspect/edit", params, true, true)
    },
    removeInspectInfo(params) {
        return fetchPost('/device/web/inspect/remove', params, true);
    },
    getDetectList(params) {
        return fetchGet("/device/web/detect/list", params, true);
    },
    addDetectInfo(params) {
        return fetchPost("/device/web/detect/add", params, true, true)
    },
    getDetectInfo(params) {
        return fetchGet("/device/web/detect/info", params, true)
    },
    editDetectInfo(params) {
        return fetchPost("/device/web/detect/edit", params, true, true)
    },
    removeDetectInfo(params) {
        return fetchPost("/device/web/detect/remove", params, true)
    },
    addMaintenanceInfo(params) {
        return fetchPost("/device/web/maintenance/add", params, true, true)
    },
    editMaintenanceInfo(params) {
        return fetchPost("/device/web/maintenance/edit", params, true, true)
    },
    getMaintenanceList(params) {
        return fetchGet("/device/web/maintenance/list", params, true)
    },
    removeMainTenanceInfo(params) {
        return fetchPost("/device/web/maintenance/remove", params, true)
    },
    getMaintenanceInfo(params) {
        return fetchGet("/device/web/maintenance/info", params, true)
    },
    addRepairsInfo(params) {
        return fetchPost("/device/web/repairs/add", params, true, true)
    },
    editRepairesInfo(params) {
        return fetchPost("/device/web/repairs/edit", params, true, true)
    },
    getRepairesList(params) {
        return fetchGet('/device/web/repairs/list', params, true)
    },
    getRepairesInfo(params) {
        return fetchGet("/device/web/repairs/info", params, true)
    },
    removeRepairesInfo(params) {
        return fetchPost("/device/web/repairs/remove", params, true);
    },
    addDeviceStatusRecord(params) {
        return fetchPost("/device/web/open/record/add", params, true, true);
    },
    editDeviceStatusRecord(params) {
        return fetchPost("/device/web/open/record/edit", params, true, true)
    },
    getDeviceStatusRecordList(params) {
        return fetchGet("/device/web/open/record/list", params, true);
    },
    removeDeviceStatusRecord(params) {
        return fetchPost("/device/web/open/record/remove", params, true);
    },
    getDeviceStatusRecordInfo(params) {
        return fetchGet("/device/web/open/record/info", params, true);
    },
    getOverviewFrequency(params) {
        return fetchGet("/device/web/machine/overview/get/frequency", params, true);
    },
    getOverviewFault(params) {
        return fetchGet("/alarm/web/alarm/get/fault/num", params, true);
    },
    getOverviewRanking(params) {
        return fetchGet("/alarm/web/alarm/get/machine/ranking", params, true);
    },
    getOverviewDeactivate(params) {
        return fetchGet("/device/web/machine/overview/get/deactivate", params, true);
    },
    getReportList(params) {
        return fetchGet("/device/report/list", params, true);
    },
    removeReport(params) {
        return fetchPost("/device/report/remove", params, true);
    },
    overviewAll(params) {
        return fetchGet("/device/web/machine/overview/get/all", params, true);
    },
    machinePendingAlarm(params) {
        return fetchGet("/alarm/web/alarm/get/machine/alarm/list", params, true);
    },
    machineGroupAlarm(params) {
        return fetchGet("/alarm/web/alarm/get/group/alarm/machine", params, true);
    },
    inspectTemplate(filename) {
        return downloadFile("/device/web/inspect/get-import-template", filename);
    },
    detectTemplate(filename) {
        return downloadFile("/device/web/detect/get-import-template", filename,);
    },
    repairsTemplate(filename) {
        return downloadFile("/device/web/repairs/get-import-template", filename);
    },
    maintenanceTemplate(filename) {
        return downloadFile("/device/web/maintenance/get-import-template", filename);
    },
    maintenanceImport(params) {
        return fetchPost("/device/web/maintenance/import/record", params, true, false, false, 'multipart/form-data');
    },
    inspectImport(params) {
        return fetchPost("/device/web/inspect/import/record", params, true, false, false, 'multipart/form-data');
    },
    detectImport(params) {
        return fetchPost("/device/web/detect/import/record", params, true, false, false, 'multipart/form-data');
    },
    repairsImport(params) {
        return fetchPost("/device/web/repairs/import/record", params, true, false, false, 'multipart/form-data');
    },
    deviceExportCsv(filename, params, type) {
        return downloadFile("/device/web/machine/spectrum/export-csv", filename, params, type);
    },
    dataAnnotation(params) {
        return fetchPost("/device/web/machine/data/dataAnnotation", params, true,true);
    },
    batchTrain(params) {
        return fetchPost("/device/web/train/start/machineIds", params, true,true);
    },
    featureList(params){
        return fetchGet("/device/web/machine/feature/list", params, true);
    },
    deviceFindGroupTime(params){
        return fetchGet("/device/web/device/findGroupTime", params, true);
    },
    manualTraining(params) {
        return fetchPost("/device/web/machine/manual/training", params, true);
    },
    deviceFindMachineList(params){
        return fetchGet("/device/web/machine/find/machineList", params, true);
    },
    addMachineGroup(params) {
        return fetchPost("/device/web/machineGroup/add", params, true,true);
    },
    editMachineGroup(params) {
        return fetchPost("/device/web/machineGroup/edit", params, true,true);
    },
    machineGroupInfo(params){
        return fetchGet("/device/web/machineGroup/info", params, true);
    },
    removeMachineGroup(params) {
        return fetchPost("/device/web/machineGroup/remove", params, true);
    },
    machineGroupList(params){
        return fetchGet("/device/web/machineGroup/list", params, true);
    },
    sensorOfflineList(params){
        return fetchGet("/device/web/device/get/offline/sensor/list", params, true);
    },
    machineHealthList(params){
        return fetchGet("/device/web/machine/no/health/list", params, true);
    },
    //指标数据
    machineIndicatorData(params){
        return fetchGet("/device/web/dt/indicator/data/list", params, true);
    },
    //指标数据新
    machineIndicatorDataV2(params){
        return fetchGet("/device/web/dt/indicator/data/v2/list", params, true);
    },
    //频谱数据
    machineSpectrumData(params){
        return fetchGet("/device/web/dt/fft/list", params, true);
    },
    //多时域波形
    machineCombineData(params){
        return fetchGet("/device/web/dt/combine/waveform/list", params, true);
    },
    //倒谱分析
    machineCepData(params){
        return fetchGet("/device/web/dt/compute/cep/strum/v2/list", params, true);
    },
    //阶次分析
    machineCalculateRPMData(params){
        return fetchGet("/device/web/dt/calculateRPM/list", params, true);
    },
    //包络趋势
    machineHilbertData(params){
        return fetchGet("/device/web/dt/compute/hilbert/envelope/list", params, true);
    },
    //获取图形数据坐标轴时间
    getRraphicList(params) {
        return fetchGet("/device/web/dt/graphic/time/list", params, true);
    },
    //获取滤波波形
    getRraphicValueList(params) {
        return fetchGet("/device/web/dt/graphic/time/value/list", params, true);
    },
    //瀑布图
    getWaterfallList(params) {
        return fetchGet("/device/web/dt/waterfall/plot/list", params, true);
    },
    //波形数据
    getWaveformList(params) {
        return fetchGet("/device/web/dt/waveform/data/list", params, true);
    },
    // 获取包络解调
    getTrendData(params) {
        return fetchGet("/device/web/dt/trend/data/list", params, true);
    },
    //倒谱图
    getCapstrumData(params) {
        return fetchGet("/device/web/dt/capstrum/data/list", params, true);
    },
    //滤波数据
    getDtfftList(params) {
        return fetchGet("/device/web/dt/fft/db/list", params, true);
    },
    //波形滤波数据
    getDtFilterList(params) {
        return fetchGet("/device/web/dt/filter/data/list", params, true);
    },
    //设备健康
    deviceHealthState(params) {
        return fetchGet("/device/web/dt/find/healthState", params, true);
    },
    //离线通知
    deviceOfflineNotification(params) {
        return fetchGet("/device/web/dt/find/offlineNotification", params, true);
    },
    //设备报警趋势
    deviceAlarmStatistics(params) {
        return fetchGet("/alarm/web/alarm/get/alarm/machine/statistics", params, true);
    },
    //设备时间稼动率
    deviceActivation(params) {
        return fetchGet("/device/web/dt/find/Activation", params, true);
    },
    //新增采集器
    addModbusV3(params) {
        return fetchPost("/device/web/modbus/v3/add", params, true,true);
    },
    //编辑采集器
    editModbusV3(params) {
        return fetchPost("/device/web/modbus/v3/edit", params, true,true);
    },
    //iso报警配置
    getMachineIsoRole(params) {
        return fetchGet("/device/web/machine/other/isoRole/list", params, true);
    },
    //设备类型统计
    getMachineCountType(params) {
        return fetchGet("/device/web/machine/other/count/machine/type", params, true);
    },
    //区域设备传感器树
    getMachineAreaTree(params) {
        return fetchGet("/device/web/machine/area/v2/list", params, true);
    },
    //获取指标数据
    getIndicatorList(params) {
        return fetchGet("/device/web/dt/indicator/data/v3/list", params, true);
    },
};
